  /* Navigation Bar */
  .navbar {
    display: flex;
    justify-content: center;
    /* background-color: #007bff; */
    padding: 10px;
    margin-left: auto;
  }

  /* Main Menu */
  .menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .menu > li {
    position: relative;
    margin: 0 20px;
  }

  .menu > li > a.active {
    color: #e25155;
}

  .menu > li > a {
    text-decoration: none;
    color: #333;
    padding: 10px 15px;
    display: block;
    font-weight: 500;
  }

  .menu > li > a:hover {
    background-color: #e7e8e9;
    border-radius: 5px;
  }

  /* Submenu */
  .submenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .submenu li {
    position: relative;
  }

 
  .submenu li a {
    text-decoration: none;
    color: #333;
    padding: 10px 15px;
    display: block;
    white-space: nowrap;
  }

  .submenu li a:hover {
    background-color: #f0f0f0;
  }

  /* Submenu > Submenu */
  .submenu .submenu {
    top: 0;
    left: 100%;
  }

  /* Show Submenu on Hover */
  .menu li:hover > .submenu {
    display: block;
  }