@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  font-size: 18px;
  color: #333;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
}
::selection {
  color: white;
  background: #060000;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  margin-top: 85px;
}

p {
  font-size: 1.0625rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}
p {
  margin: 0 0 10px;
}

span,
button,
a {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.h3,
h3 {
  font-size: 24px;
}

a {
  font-size: 1.0625rem;
  color: #6c757d;
  text-decoration: none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

a:hover {
  color: #666;
  text-decoration: none;
}

.slick-dots {
  bottom: 30px !important;
}

.slick-dots li.slick-active button:before {
  background-color: #ffc000;
  width: 15px;
  height: 15px;
  color: transparent !important;
}

.slick-dots li button:before {
  background-color: #80c33f;
  width: 15px !important;
  height: 15px !important;
  border: 3px solid #fff;
  border-radius: 0;
  color: transparent !important;
}

.headerBlink .MuiBadge-badge {
  animation: animate 1.5s linear infinite;
}

.slick-next, .slick-prev{
  color: #025828 !important;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}
