.whatsapp-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #25D366;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s ease;
  }
  
  .whatsapp-button:hover {
    transform: scale(1.1);
  }
  